import * as React from "react"
import { useEffect, useRef, useState } from "react";
import { Camera } from "./Camera";
import { ContractClient, PhotoContractState, UserValidationResponse } from "../services/apiClient"
import { Button, TextField } from "@material-ui/core";
import { PictureValidation } from "./PictureValidation";
var Client = new ContractClient()
export const Main = () => {
    const [ContractState, setContractState] = useState<PhotoContractState>(null)
    const [UserValidation, setUserValidation] = useState<UserValidationResponse>(new UserValidationResponse())
    const [ContractError, setContractError] = useState<string>(null)
    const [TakePicture, setTakePicture] = useState<boolean>(false)
    const [Finished, setFinished] = useState<boolean>(false)
    const [Started, setStarted] = useState<boolean>(false)
    const [ValidationResponseMessage, setValidationResponseMessage] = useState<string>(null)
    const [ValidationIsSuccess, setValidationIsSuccess] = useState<boolean>(false)

    function WindowResizeListener() {
        if (MainRef.current != null) {
            MainRef.current.style["height"] = window.innerHeight + "px";
            MainRef.current.style["width"] = window.innerWidth + "px";
        }
    }

    useEffect(() => {
        HandleUrl();
        window.addEventListener("resize", WindowResizeListener);
        return () => { window.removeEventListener("resize", WindowResizeListener); }
    }, [])
    const HandleUrl = () => {
        const params = new URLSearchParams(window.location.search)
        var uid = params.get("uid");
        if (uid != null) {
            HandleUid(uid);
        }
        else
            setContractError("Aucun Contrat dans la Requête.")
    }
    const HandleUid = async (uid: string) => {
        var ContractState: PhotoContractState = await Client.getContract(uid);
        if (ContractState.contractUid == null)
            return setContractError("Aucun Contrat ne correspond à l'Uid.");
        if (ContractState.userEndValidated == true)
            return setContractError("Contrat déjà Validé.");
        setContractState(ContractState);
        setStarted(true);
        setTakePicture(true);
        setUserValidation(new UserValidationResponse({ idReq: uid }))
    }
    const HandlePicture = (Image64: string) => {
        setTakePicture(false);
        setUserValidation(prevState => new UserValidationResponse({ ...prevState, b64EncodedImage:Image64 }))
    }
    const SendPicture = async () => {
        var Result = await Client.updateContract(UserValidation)
        if (Result.success == true)
            setFinished(true)
        if (Result.message)
            setValidationResponseMessage(Result.message)
        setValidationIsSuccess(Result.success)

    }
    const AbortSend = () => {
        setTakePicture(true)
    }
    const MainRef = useRef(null)
    return (
        <div ref={MainRef} style={{backgroundColor:"#f0f0f0", position: "fixed", top: "0", bottom: "0", width: window.innerWidth+"px", height: window.innerHeight+"px" }}>
            {(Finished || !Started || ContractError)
                ?
                <div style={{fontSize: "45px", font: "verdana",color:"black", display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent:"center", alignItems:"center", width:"100vw", height:"100vh"}}>
                    {ContractError ?? <><span style={{display:"block", marginBottom:"15px"}}>{Finished && "Photo envoyée avec succès"}</span></>}
                </div>
                :
                <>
                    <Camera OnReturn={UserValidation.b64EncodedImage ? () => {setTakePicture(false)} : null } Hidden={!TakePicture} OnTakePicture={HandlePicture} />
                    {!TakePicture &&
                        (ValidationResponseMessage
                        ?
                        <div style={{ fontSize: "45px", font: "verdana", display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent:"center", alignItems:"center", width:"100vw", height:"100vh"}}>
                            {ValidationResponseMessage}
                            <Button variant="contained" color="primary" style={{ display: "block",textAlign:"center", borderRadius: "0", minWidth: "0%", minHeight: "0%", overflow: "hidden", font: "verdana", fontSize: "40px" }} onClick={() => { setValidationResponseMessage(null) }}>OK</Button>
                        </div>
                        :
                        <PictureValidation Send={SendPicture} Abort={AbortSend} UpdateState={setUserValidation} State={UserValidation} />
                        )
                    }
                </>
            }
        </div>
        )
}
