import * as React from "react"
import { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam"
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import ReplyIcon from '@mui/icons-material/Reply';

export interface CameraProps {
    OnTakePicture: Function
    Hidden: boolean
    OnReturn?: Function
}
interface CameraConfiguration {
    width: number
    height: number
    aspectRatio: number
}
interface DisplayConfiguration {
    displayedWidth: number
    displayedHeight: number

}
export const Camera = (props: CameraProps) => {
    const [Orientation, setOrientation] = useState<string>(null);
    const [FacingMode, setFacingMode] = useState<string>("environment");
    const [CameraConfiguration, setCameraConfiguration] = useState<CameraConfiguration>(null);
    const [Ready, setReady] = useState<boolean>(false)
    const [DisplayConfiguration, setDisplayConfiguration] = useState<DisplayConfiguration>(null);
    const webcamRef: React.LegacyRef<Webcam> = useRef(null);
    const checkOrientation = () => {
        var _Orientation = getOrientation();
        if(_Orientation!=Orientation)
            setOrientation(_Orientation)
    }
    const getOrientation = () => {
        if (window.innerWidth > window.innerHeight)
            return "landscape";
        else
            return "portrait"
    }
    const CapturePhoto = async () => {
        if (webcamRef.current != null) {
            var ImageContent = await webcamRef.current.getScreenshot();
            var ImageB64 = ImageContent.split(",", 2)[1];
            props.OnTakePicture(ImageB64);
        }
    }
    const _ResizeObserver = useRef<ResizeObserver>(null)
    useEffect(() => {
        RefreshSettings();
    },[])
    useEffect(() => {
        if (_ResizeObserver.current)
            _ResizeObserver.current.disconnect()
        _ResizeObserver.current = new ResizeObserver(checkOrientation)
        _ResizeObserver.current.observe(document.body)
    }, [Orientation])
    const RefreshSettings = async () => {
        var cameraSettings = webcamRef.current.stream?.getVideoTracks()?.[0]?.getSettings();
        if(cameraSettings==null)
            return;
        var orientation = getOrientation();
        var width = cameraSettings.width
        var height = cameraSettings.height
        if(CameraConfiguration==null){
            setCameraConfiguration({width:orientation == "portrait" ? width : height, height:orientation == "portrait" ? height : width, aspectRatio: cameraSettings.aspectRatio})
            setDisplayConfiguration({displayedHeight:orientation == "portrait" ? height : width,displayedWidth:orientation == "portrait" ? width : height})
        }
        else
        setReady(true)
    }
    const switchCamera = () => {
        var _CameraUsed = FacingMode == "environment" ? "user" : "environment"
        if(_CameraUsed != FacingMode){
            setFacingMode(_CameraUsed);
            RefreshSettings();
        }
    }
    return (
        <div style={{ display: props.Hidden ? "none" : "block",width: "100%", height: "100%", position: "relative",overflow:"hidden"}}>
            <div className="webcam-img" style={{ zIndex: 1, position: "absolute", display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent:"center", alignItems:"center", width:"100%", height:"100%"}}>
                <Webcam
                    style={{visibility:(CameraConfiguration==null || !Ready) ? "hidden": "visible", maxWidth:"100%", maxHeight:"100%"}}
                    id="WebcamVideo"
                    className={"fill"}
                    audio={false}
                    ref={webcamRef}
                    key={FacingMode}
                    onCanPlay={() => {RefreshSettings();}}
                    hidden={props.Hidden}
                    screenshotFormat="image/jpeg"
                    screenshotQuality={1}
                    width={DisplayConfiguration==null? 0  : DisplayConfiguration.displayedWidth}
                    height={DisplayConfiguration==null? 0 : DisplayConfiguration.displayedHeight}
                    forceScreenshotSourceSize={true}
                    mirrored={FacingMode=="user"}
                    videoConstraints={CameraConfiguration==null ? null : {
                        width:CameraConfiguration.height*2,
                        height:CameraConfiguration.width*2,
                        aspectRatio:CameraConfiguration.aspectRatio,
                        facingMode: FacingMode
                    }}
                />
            </div>
            {CameraConfiguration!=null &&
                <>
                    {props.OnReturn &&
                    <div onClick={() => { props.OnReturn() }} className="reply-button tool-icon">
                        <ReplyIcon style={{ fontSize: "80px" }} />
                    </div>
                    }
                    <div onClick={() => { switchCamera() }} className="switch-camera-button tool-icon">
                        <CameraswitchIcon style={{ fontSize:"80px" }} />
                    </div>
                    <div onClick={CapturePhoto} className="center-button">
                        <div className="photo-button">
                            <div className="background-circle"/>
                            <div className="circle"/>
                            <div className="ring"/>
                        </div>
                    </div>
                </>
            }
            </div>
        )
}
