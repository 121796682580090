import React, { Component } from 'react';
import { Route } from 'react-router';
import { Camera } from './components/Camera';
import { Main } from './components/Main';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
      return (
          <>
              <Route exact path='/' component={Main} />
          </>
    );
  }
}