import * as React from "react"
import { useEffect, useRef, useState } from "react";
import { Camera } from "./Camera";
import { ContractClient, PhotoContractState, UserValidationResponse } from "../services/apiClient"
import { Button, TextField } from "@material-ui/core";
export interface PictureValidationProps {
    UpdateState: Function,
    State: UserValidationResponse,
    Abort: Function,
    Send:Function
}
export const PictureValidation = (props: PictureValidationProps) => {
    const UpdateComment = (Comment: string) => {
        props.UpdateState(prevState => new UserValidationResponse({ ...prevState, comment: Comment }))
    }
    return (
        <div style={{ boxSizing: "border-box", width: "100%", height: "100%", textAlign: "center", background: "linear-gradient(90deg, rgba(116,116,116,0.2) 0%, rgba(176,176,176,0.2) 50%, rgba(116,116,116,0.2) 100%)" }}>
            <div style={{boxSizing:"border-box", width: "100%", height: "80%",padding:"5%" }}>
                <div className="photo-wrapper" style={{ width: "100%", height: "70%" }}>
                <img src={"data:image/jpeg;base64," + props.State.b64EncodedImage} style={{ maxWidth:"100%",maxHeight:"100%"}} />
            </div>
            <div style={{width: "100%", height: "30%",paddingTop:"4%" }}>
                    <TextField multiline={true} rowsMax={3} style={{ width: "100%",height:"26%" }} value={props.State.comment} type="text" id="Nom" label={"Commentaire"} placeholder="Commentaire" onChange={(evt) => { UpdateComment(evt.currentTarget.value) }} />
                </div>
            </div>
            <div style={{ width: "100%", height: "20%" }}> 
                <div className="controls-wrapper" style={{ display: "block", width: "100%", height: "100%"  }}>
                    <Button variant="contained" color="secondary" className="control round-button" onClick={() => { props.Abort() }}>Éditer</Button>
                    <Button variant="contained" color="primary" className="control round-button" onClick={() => { props.Send() }}>Envoyer</Button>
                </div>
                </div>
            </div>
        )
}
